import React, { useEffect, useState, useCallback } from "react";
import { LAMPORTS_PER_SOL, PublicKey, GetProgramAccountsFilter } from "@solana/web3.js";
import { getTrades, getLastTrade } from "../../../../src/modules/clients/vercel";
// import config from '../../../api/settings/settings.json';
import { fetchNewPool } from "../../../../src/modules/fetchNewPool";

import { HolderAccount } from "../../../../src/interfaces/interfaces";

import axios from "axios";

import { connection, rpc, trader } from "../../../../src/config";
import { ASSOCIATED_TOKEN_PROGRAM_ID, TOKEN_PROGRAM_ID, getAssociatedTokenAddress } from "@solana/spl-token";
import { log } from "console";
import { sleep } from "../../../../src/modules/utils";

interface Settings {
  traderPrivateKey: string;
  tradeAmount: number;
  tpPercent: number;
  slPercent: number;
  maxHoldingTime: number;
  maxSOLCap: number;
  maxHoldersPercentageCap: number;
  socialsThreshold: number;
  skipCreator: boolean;
}

interface PoolData {
  name?: string;
  symbol?: string;
  description?: string;
  image_uri?: string;
  twitter?: string;
  telegram?: string;
  website?: string;
  mint?: string;
  bonding_curve?: string;
  creator?: string;
  market_cap?: number;
  real_sol_reserves?: number;
  real_token_reserves?: number;
  virtual_sol_reserves?: number;
  virtual_token_reserves?: number;
  created_timestamp_ago?: string;
  last_trade_timestamp_ago?: string;
  last_reply_ago?: string;
  buyAmount?: number;
  currentAmount?: number;
  elapsed?: string;
  holders?: HolderAccount[];
}

interface SearcherProps {
  onNewPoolData: (data: PoolData | null) => void; // Callback to pass data to parent
  onUpdateHoldersData: (data: HolderAccount[]) => void; // Adjusted to pass holder
  stopTrading: () => void;  // Stop trading function from Lobby
  skipTrade: boolean;  // Handle skip action
}

const Searcher: React.FC<SearcherProps> = ({ onNewPoolData, onUpdateHoldersData, stopTrading, skipTrade }) => {
  const [previousMint, setPreviousMint] = useState<string | null>(null);
  const [initialized, setInitilized] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [currentPoolData, setCurrentPoolData] = useState<PoolData[]>([]);

  // Load settings from localStorage with default values if not present
  const loadSettingsFromLocalStorage = (): Settings => {
    const settingsData = localStorage.getItem('appSettings');
    return settingsData ? JSON.parse(settingsData) : {
      traderPrivateKey: "",
      tradeAmount: 0.1,
      tpPercent: 1.2,
      slPercent: 0.9,
      maxHoldingTime: 10,
      maxSOLCap: 100,
      maxHoldersPercentageCap: 30,
      socialsThreshold: 2,
      skipCreator: false,
    };
  };

  // Update `settings` constant to use the new load function
  // const settings: Settings = config;
  const settings: Settings = loadSettingsFromLocalStorage();
  
  const tokenDecimals = 10 ** 6;
  const tokenTotalSupply = 1000000000 * tokenDecimals;

  const getTokenHolders = useCallback(async (poolData: PoolData) => {
    try {
      if (!initialized) {
        return;
      }

      if (!poolData.mint || !poolData.bonding_curve) return;
  
      // console.log('upd m: ', poolData.mint);
      // console.log('upd b: ', poolData.bonding_curve);

      const mintPublicKey = new PublicKey(poolData.mint);
      const filters: GetProgramAccountsFilter[] = [
        { dataSize: 165 },
        {
          memcmp: {
            offset: 0,
            bytes: mintPublicKey.toBase58(),
          },
        },
      ];
  
      await sleep(200);
      const tokenAccounts = await connection.getProgramAccounts(TOKEN_PROGRAM_ID, {
        filters: filters,
      });
  
      if (!poolData.creator) return;
      await sleep(200);
      const creatorATA = await getAssociatedTokenAddress(mintPublicKey, new PublicKey(poolData.creator));

      await sleep(200);
      const bondingCurveAccounts = await connection.getTokenAccountsByOwner(new PublicKey(poolData.bonding_curve), { programId: TOKEN_PROGRAM_ID });
      // console.log('bondingCurveAccounts: ', bondingCurveAccounts);
      
      let bondingCurveATA: PublicKey | null = null;

      for (const account of bondingCurveAccounts.value) {
        try {
          await sleep(200);
          const accountInfo = await connection.getParsedAccountInfo(account.pubkey);
          if (accountInfo.value && accountInfo.value.data && 'parsed' in accountInfo.value.data) {
            const accountMint = accountInfo.value.data.parsed.info.mint;
            if (accountMint === mintPublicKey.toBase58()) {
              // console.log('found bonding curve');
              bondingCurveATA = account.pubkey;
            }
          }

        } catch (error) {}
      }
      
      // await sleep(200);
      // const tokenBalance = await connection.getTokenAccountBalance(creatorATA);
      
      const holders: HolderAccount[] = [];

      try {
        for (const account of tokenAccounts) {
          try {
            const parsedAccountPubkey: any = new PublicKey(account.pubkey);
            const parsedAccountOwner: any = new PublicKey(account.account.owner);
            const parsedAccountLamports: any = new Number(account.account.lamports);
            const parsedAccountRentEpoch: any = account.account.rentEpoch;
    
            try {
              await sleep(200);
              const tokenBalance = await connection.getTokenAccountBalance(parsedAccountPubkey);
      
              // console.log("Token Balance:", tokenBalance);

              if (bondingCurveATA && account.pubkey.toBase58() != bondingCurveATA.toBase58()) { 
                if (account.pubkey.toBase58() === creatorATA.toBase58()) {
                  const holder: HolderAccount = {
                    is_creator: true,
                    account: parsedAccountPubkey,
                    balance: Number(tokenBalance.value.amount),
                    rentEpoch: parsedAccountRentEpoch,
                  };
                  holders.push(holder);
                } else {
                  const holder: HolderAccount = {
                    is_creator: false,
                    account: parsedAccountPubkey,
                    balance: Number(tokenBalance.value.amount),
                    rentEpoch: parsedAccountRentEpoch,
                  };
                  holders.push(holder);
                }
              }
            } catch (error) {}
          } catch (error) {}
        };    

        onUpdateHoldersData(holders); // Send updated holders to parent
        return holders;   

    } catch (error) {
      // console.error("Error fetching token holders:", error);
      return [];
    }
  } catch (error) {}
  }, [connection, onUpdateHoldersData]);

  const main = useCallback(async () => {
    setLoading(true);
    try {
      const latestTrade = await getLastTrade();      
      const newCoin = latestTrade.mint;
      const newPool = await fetchNewPool(newCoin);

      if (newPool) {
        const socialUrls = [newPool.website, newPool.telegram, newPool.twitter];
        const nonNullUrls = socialUrls.filter(Boolean);
        
        const uniqueUrls = new Set(nonNullUrls);

        if (uniqueUrls.size < settings.socialsThreshold) {
          return; 
        } else {
          if (newPool.mint !== previousMint) {
            // console.log('new m: ', newPool.mint);
            // console.log('new b: ', newPool.bonding_curve);

            setCurrentPoolData(newCoin);
            
            // await sleep(200); // Sleep for 5 seconds before fetching holders
            const poolHolders = await getTokenHolders(newPool);
            // console.log("poolHolders: ", poolHolders);
            
            onNewPoolData({
              ...newPool,
              holders: poolHolders,  // Pass holders in PoolData
              elapsed: `${new Date().getTime() - latestTrade.createdAt}ms`, // Example of elapsed time
            });

            // // Open pool URL in browser
            // const poolUrl = `https://pump.fun/${newCoin}`;
            // await fetch('/api/browser/puppeteer', {
            //   method: 'POST',
            //   headers: {
            //     'Content-Type': 'application/json'
            //   },
            //   body: JSON.stringify({ url: poolUrl })
            // });

            setPreviousMint(newPool.mint);
          }
        }
      }
      
      setLoading(false);
      return newPool;

    } catch (error) {
      console.error("Error fetching pool data:", error);
      stopTrading(); // Stop trading on error
    }
  }, [previousMint, onNewPoolData, stopTrading, getTokenHolders]);

  // Call main function once when cholderomponent is mounted
  useEffect(() => {
    if (!initialized) {
      main();
      setInitilized(true);
    }
  }, [main]);

  useEffect(() => {
    const mainInterval = setInterval(async () => {
      try {
        const poolData = await main();
        setCurrentPoolData(poolData);
      } catch (error) {
        console.error("Error fetching pool data:", error);
      }
    }, 5000); // Fetch new pool data every 30 seconds
  
    // Clear the interval on component unmount
    return () => clearInterval(mainInterval);
  }, [main]);

  // useEffect(() => {
  //   const mainInterval = setInterval(async () => {
  //     try {
  //       await getTokenHolders(currentPoolData);
  //     } catch (error) {
  //       console.error("Error fetching pool data:", error);
  //     }
  //   }, 2000); // Fetch new pool data every 30 seconds
  
  //   // Clear the interval on component unmount
  //   return () => clearInterval(mainInterval);
  // }, [getTokenHolders, currentPoolData]);

  // UseEffect to handle skip trade
  useEffect(() => {
    if (skipTrade) {
      main(); // Call main to fetch new data immediately when skipTrade is fired
    }
  }, [skipTrade]); // Trigger effect when skipTrade changes

  return null; // Return nothing or appropriate JSX for Searcher
};

export default Searcher;
