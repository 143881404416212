import { useState, useEffect } from "react";
import React from 'react';
import useUserSOLBalanceStore from '../../../stores/useUserSOLBalanceStore';
import { useWallet, useConnection } from '@solana/wallet-adapter-react';
import { connection } from '../../../../src/config';
import { HolderAccount } from '../../../../src/interfaces/interfaces';
import KOTH from "../global-stats/koth"
import Searcher from './searcher';
import Resume from './resume';

interface PoolData {
  name?: string;
  symbol?: string;
  description?: string;
  bonding_curve?: string;
  image_uri?: string;
  twitter?: string;
  telegram?: string;
  website?: string;
  mint?: string;
  creator?: string;
  market_cap?: number;
  real_sol_reserves?: number;
  real_token_reserves?: number;
  virtual_sol_reserves?: number;
  virtual_token_reserves?: number;
  created_timestamp_ago?: string;
  last_trade_timestamp_ago?: string;
  last_reply_ago?: string;
  buyAmount?: number;
  currentAmount?: number;
  elapsed?: string;
  holders?: HolderAccount[];
}


const Portal: React.FC<{}> = ({ }) => {
  const wallet = useWallet();
  // const { connection } = useConnection();
  const balance = useUserSOLBalanceStore((s) => s.balance);
  const { getUserSOLBalance } = useUserSOLBalanceStore();

  const [poolData, setPoolData] = useState<PoolData | null>(null); // State to hold the pool data
  const [holdersData, setHoldersData] = useState<HolderAccount[] | null>(null); // State to hold the holder data

  const [trading, setTrading] = useState<boolean>(false); // Local state to control trading
  const [skipTrade, setSkipTrade] = useState<boolean>(false); // Local state to control trade skipping

  const [showLastTraded, setShowLastTraded] = useState<boolean>(true); // State to control portal view

  // Fetch user's SOL balance when wallet is connected
  useEffect(() => {
    if (wallet.publicKey) {
      getUserSOLBalance(wallet.publicKey, connection);
    }
  }, [wallet.publicKey, connection, getUserSOLBalance]);

  // Automatically start trading when the Portal component is mounted
  useEffect(() => {
    startTrading(); // Start trading when Portal is shown
    handleSkipTrade(); // Skip the first trade

    // Cleanup function to stop trading when the component unmounts
    return () => {
      stopTrading(); // Ensure trading stops when Portal is closed
    };
  }, []); // Empty dependency array ensures this runs once when mounted

  const handleClose = () => {
    // onClose(); // Invoke onClose callback
    setPoolData(null); // Reset pool data state
    setHoldersData(null); // Reset holder data state
    setShowLastTraded(false);
  };

  // Callback function to receive new pool data
  const handleNewPoolData = (data: PoolData | null) => {
    setPoolData(data); // Update pool data state
  };

  // Callback function to receive new holders data
  const handleUpdateHoldersData = (data: HolderAccount[] | null) => {
    // console.log("Incoming data:", data);
    // console.log("Current holdersData:", holdersData);
    setHoldersData(data);
  };

  // Toggle function for showing/hiding the portal
  const handleStart = () => setShowLastTraded(true);

  const startTrading = () => {
    setTrading(true); // Set trading state to true
  };

  const stopTrading = () => {
    setTrading(false); // Set trading state to false
  };

  const handleSkipTrade = () => {
    setSkipTrade(true); // Set skipTrade to true
    // Reset skipTrade after 250 ms (adjust time as necessary)
    setTimeout(() => setSkipTrade(false), 250);
  };

  return (
    <main className="p-4">
        <div className="w-[90vw] md:w-[95vw] grid grid-cols-1 xl:grid-cols-9 space-y-2 xl:space-y-0 xl:gap-4">
          {/* <div className="col-span-9 da-container backdrop-saturate-150 backdrop-blur-md bg-[#1b1d28d5]"></div> */}
            <div className="col-span-2">
                <div className="grid grid-cols-1 gap-4">

                    <div className="koth-container">
                      <KOTH />
                    </div>

                    {/* <div className="da-container backdrop-saturate-150 backdrop-blur-md bg-[#1b1d28d5]"></div>
                    <div className="da-container backdrop-saturate-150 backdrop-blur-md bg-[#1b1d28d5]"></div> */}
                </div>
            </div>

            {!showLastTraded ? (
              // Show Start button initially
              <div className="md:absolute md:right-0 md:bottom-0 md:m-4">
                <button 
                    onClick={handleStart} 
                    className="backdrop-blur-md bg-[#1b1d287e] text-8xl text-white py-4 px-16 transition duration-200 border-2 border-[#86EFAC] rounded-lg hover:bg-[#86EFAC] hover:text-black"
                >
                    Start
                </button>
              </div>
            ) : (
              // Show Portal content once the Start button is clicked
              <>
                {/* Additional Portal content can be added here */}
                <div className="col-span-7 ds-container">
                      <div className="screen top-screen">
                        <div className="relative flex flex-col justify-center w-full h-full">
                            <Searcher 
                            onNewPoolData={handleNewPoolData} 
                            onUpdateHoldersData={handleUpdateHoldersData}
                            stopTrading={stopTrading} // Pass stop trading function
                            skipTrade={skipTrade}  // Pass trading state to Searcher
                            />
                            <Resume poolData={poolData} holdersData={holdersData} /> {/* Pass the pool data to Resume */}
                        </div>
                      </div>
                      <div className="screen bottom-screen z-10">
                        <h4 className="md:w-full text-2xl text-slate-300 my-2 w-full">
                            {/* {wallet.connected ? ( */}
                              <div className="flex flex-col justify-center px-2">
                                  <div className="flex flex-col justify-center">
                                  <div className="grid grid-cols-3 gap-2 justify-center">
                                      {/* <button onClick={handleSkipTrade} className="font-medium text-[1.2rem] col-span-3 text-white py-2 px-4 hover:bg-[#86EFAC] hover:text-black transition duration-200 border border-[#86EFAC] rounded-lg">
                                      Skip
                                      </button> */}
                                      {trading ? (
                                      <button 
                                          onClick={stopTrading} 
                                          className="font-medium text-[1.2rem] col-span-2 text-black py-2 px-4 bg-[#ef8686] hover:bg-[#d13838] transition duration-200 border border-[#ef8686] hover:border-[#d13838] rounded-lg"
                                      >
                                          Pause
                                      </button>
                                      ) : (
                                      <button 
                                          onClick={startTrading} 
                                          className="font-medium text-[1.2rem] col-span-2 text-white py-2 px-4 hover:bg-[#86EFAC] hover:text-black transition duration-200 border border-[#86EFAC] rounded-lg"
                                      >
                                          Resume
                                      </button>
                                      )}
                                      <button onClick={handleClose} className="font-medium text-[1.2rem] col-span-1 text-white py-2 px-4 hover:bg-[#86EFAC] hover:text-black transition duration-200 border border-[#86EFAC] rounded-lg">
                                      Close
                                      </button>
                                  </div>
                                  </div>
                              </div>
                            {/* ) : (
                              <div className="flex flex-col justify-center text-center">
                                  <p>Please connect your wallet to trade.</p>
                              </div>
                            )} */}
                        </h4>
                      </div>
                  </div>
              </>
            )}

        </div>
    </main>
  );
}

export default Portal;