import { getCoinData } from './clients/vercel';

// Helper function to convert timestamps to seconds ago, handling null values
function timeAgo(timestamp: number | null): number | null {
    return timestamp ? Math.floor((Date.now() - timestamp) / 1000) : null;
}

const tokenDecimals = 10 ** 6;
let initialRealSolReserves = 0;
let initialVirtualTokenReserves = 1073000000 * tokenDecimals;
let initialRealTokenReserves = 793100000 * tokenDecimals;

export async function fetchNewPool(mint: string) {
    try {
        const newPool = await getCoinData(mint);
        
        // console.log(newPool.name);
        // console.log(newPool.mint);
        // console.log(newPool.bonding_curve);

        // Current time in milliseconds
        const now = Date.now();

        // Add converted fields to the response
        newPool.created_timestamp_ago = timeAgo(newPool.created_timestamp);
        newPool.last_trade_timestamp_ago = timeAgo(newPool.last_trade_timestamp);
        newPool.last_reply_ago = timeAgo(newPool.last_reply);
        if (newPool.king_of_the_hill_timestamp) {
            newPool.king_of_the_hill_timestamp_ago = timeAgo(newPool.king_of_the_hill_timestamp);
        }

        // Update reserves from the pool data
        initialRealSolReserves = newPool.real_sol_reserves || initialRealSolReserves;
        initialVirtualTokenReserves = newPool.virtual_token_reserves || initialVirtualTokenReserves;
        initialRealTokenReserves = newPool.real_token_reserves || initialRealTokenReserves;
        
        // Calculate current price
        const currentPrice = calculateCurrentPrice(initialRealSolReserves, initialRealTokenReserves);
        // console.log('Current Price:', currentPrice);

        return { ...newPool, currentPrice };
    } catch (error) {
        // console.error('Error fetching new pools:', error);
        return null;
    }
}

// Function to calculate current price based on reserves
function calculateCurrentPrice(realSolReserves: number, realTokenReserves: number) {
    if (realTokenReserves === 0) {
        throw new Error('Real token reserves cannot be zero for price calculation.');
    }
    return realSolReserves / realTokenReserves;
}
